import React from "react"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import TextLink from "@kiwicom/orbit-components/lib/TextLink"
import Bus from "@kiwicom/orbit-components/lib/icons/Bus"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Common Diversions">
    <Hero title="Common Diversions" />
    <Container>
      <Stack spacing="XLarge">
        <EmberCard icon={<Bus />} title="E1">
          <EmberCardSection>
            <Stack direction="column">
              <Heading type="title5">Dundee to Edinburgh</Heading>

              <TextLink href="dundee-to-dundee-west-via-perth-road/">
                Dundee City Centre to Dundee West via Perth Road
              </TextLink>
              <TextLink href="inchture-to-st-madoes-via-errol">
                Inchture to St Madoes via Errol
              </TextLink>
              <TextLink href="bridge-of-earn-to-kinross-via-gateside">
                Bridge of Earn to Kinross via Gateside
              </TextLink>
              <TextLink href="kinross-to-rosyth-via-crossgates/">
                Kinross to Rosyth via Crossgates
              </TextLink>
              <TextLink href="kinross-to-rosyth-via-kelty">
                Kinross to Rosyth via Kelty
              </TextLink>
              <TextLink href="rosyth-to-ingliston-via-kirkliston/">
                Rosyth to Ingliston via Kirkliston
              </TextLink>
              <TextLink href="edinburgh-zoo-to-haymarket-via-ravelston-dykes/">
                Edinburgh Zoo to Haymarket via Ravelston Dykes
              </TextLink>
              <TextLink href="edinburgh-zoo-to-st-andrew-square-via-ravelston-dykes/">
                Edinburgh Zoo to St Andrew Square via Ravelston Dykes (skipping
                Haymarket)
              </TextLink>
              <TextLink href="haymarket-to-st-andrew-square-via-queen-street/">
                Haymarket to St Andrew Square via Queen Street
              </TextLink>

              <Heading type="title5">Edinburgh to Dundee</Heading>

              <TextLink href="st-andrew-square-to-haymarket-via-queen-street/">
                St Andrew Square to Haymarket via Queen Street
              </TextLink>
              <TextLink href="st-andrew-square-to-edinburgh-zoo-via-ravelston-dykes/">
                St Andrew Square to Edinburgh Zoo via Ravelston Dykes (skipping
                Haymarket)
              </TextLink>
              <TextLink href="haymarket-to-edinburgh-zoo-via-ravelston-dykes/">
                Haymarket to Edinburgh Zoo via Ravelston Dykes
              </TextLink>
              <TextLink href="edinburgh-zoo-to-rosyth-via-frb/">
                Edinburgh Zoo to Rosyth via Forth Road Bridge
              </TextLink>
              <TextLink href="ingliston-to-rosyth-via-maybury-and-frb/">
                Ingliston to Rosyth via Maybury Road and the Forth Road Bridge
              </TextLink>
              <TextLink href="ingliston-to-rosyth-via-kirkliston">
                Ingliston to Rosyth via Kirkliston
              </TextLink>
              <TextLink href="rosyth-to-kinross-via-crossgates">
                Rosyth to Kinross via Crossgate
              </TextLink>
              <TextLink href="rosyth-to-kinross-via-kelty">
                Rosyth to Kinross via Kelty
              </TextLink>
              <TextLink href="kinross-to-bridge-of-earn-via-gateside">
                Kinross to Bridge of Earn via Gateside
              </TextLink>
              <TextLink href="st-madoes-to-inchture-via-errol">
                St Madoes to Inchture via Errol
              </TextLink>
              <TextLink href="dundee-west-to-dundee-via-perth-road/">
                Dundee West to Dundee City Centre via Perth Road
              </TextLink>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard icon={<Bus />} title="E3">
          <EmberCardSection>
            <Stack direction="column">
              <Heading type="title5">Dundee to Glasgow</Heading>

              <TextLink href="dundee-to-dundee-west-via-perth-road/">
                Dundee City Centre to Dundee West via Perth Road
              </TextLink>
              <TextLink href="inchture-to-st-madoes-via-errol">
                Inchture to St Madoes via Errol
              </TextLink>
              <TextLink href="st-madoes-to-broxden-via-perth">
                St Madoes to Broxden Park and Ride via Perth
              </TextLink>
              <TextLink href="stirling-to-cumbernauld-via-stirling-city-centre">
                Stirling to Cumbernauld via Stirling City Centre
              </TextLink>
              <TextLink href="stirling-to-cumbernauld-via-denny">
                Stirling to Cumbernauld via Denny
              </TextLink>
              <TextLink href="stirling-to-cumbernauld-via-castlecary">
                Stirling to Cumbernauld via Castlecary
              </TextLink>
              <TextLink href="cumbernauld-to-glasgow-via-royston/">
                Cumbernauld to Glasgow via Royston Road
              </TextLink>

              <Heading type="title5">Glasgow to Dundee</Heading>

              <TextLink href="glasgow-to-cumbernauld-via-royston">
                Glasgow to Cumbernauld via Royston Road
              </TextLink>
              <TextLink href="cumbernauld-to-stirling-via-castlecary">
                Cumbernauld to Stirling via Castlecary
              </TextLink>
              <TextLink href="cumbernauld-to-stirling-via-denny">
                Cumbernauld to Stirling via Denny
              </TextLink>
              <TextLink href="cumbernauld-to-stirling-via-stirling-city-centre">
                Cumbernauld to Stirling via Stirling City Centre
              </TextLink>
              <TextLink href="broxden-to-st-madoes-via-perth">
                Broxden Park and Ride to St Madoes via Perth
              </TextLink>
              <TextLink href="st-madoes-to-inchture-via-errol">
                St Madoes to Inchture via Errol
              </TextLink>
              <TextLink href="dundee-west-to-dundee-via-perth-road/">
                Dundee West to Dundee City Centre via Perth Road
              </TextLink>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
